$(document).on('turbolinks:load', function() {
  addMobileFriendlyLabels();
});

// This event triggers when a jQuery AJAX request are made.
$(document).on('ajaxComplete', function() {
  addMobileFriendlyLabels();
} );

// This event triggers when a Rails AJAX request are made.
$(document).on('ajax:send', function() {
  let observer = new MutationObserver(function() {
    addMobileFriendlyLabels();
    observer.disconnect();
  });

  observer.observe(
    document.body, // Observing the entire document for changes
    {
      attributes: false,
      childList: true,
      subtree: true
    }
  );
});

function addMobileFriendlyLabels() {
  $('table.mobile-friendly').each(function() {
    let table = $(this);
    let headers = table.find('thead th');

    table.find('tbody tr').each(function() {
      let row = $(this);
      let cells = row.find('td');

      cells.each(function(index) {
        let cell = $(this);
        let header = headers.eq(index).text();

        if (cell.data('label') == undefined) {
          cell.attr('data-label', header);
          cell.html('<div class="content">' + cell.html() + '</div>');
        }
      });
    });
  });
}
