document.addEventListener("DOMContentLoaded", () => {
  const isInstallationSupported = 'BeforeInstallPromptEvent' in window;
  const isStandalone = (window.matchMedia('(display-mode: standalone)').matches || ('standalone' in navigator && navigator.standalone));
  const installButton = document.querySelector(".installation-btn");
  let installPrompt = null;

  if (!installButton || !isInstallationSupported || isStandalone) {
    return;
  }

  installButton.classList.remove("d-none");

  window.addEventListener("beforeinstallprompt", (event) => {

    event.preventDefault();
    installPrompt = event;

  });

  installButton.addEventListener("click", async () => {

    if (!installPrompt) {
      return;
    }

    const result = await installPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);

    installPrompt = null;
    installButton.classList.add("d-none");

  });

});
