$(document).on("change", "#email_setting_use_default_data", function() {
  if ($(this)[0].checked) {
    $('.email_setting_address').addClass("d-none");
    $('.email_setting_port').addClass("d-none");
    $('.email_setting_domain').addClass("d-none");
    $('.email_setting_user_name').addClass("d-none");
    $('.email_setting_password').addClass("d-none");
    $('.email_setting_authentication').addClass("d-none");
    $('.email_setting_enable_starttls_auto').addClass("d-none");
    $('.email_setting_from').addClass("d-none");

    $('.email_setting_sender').removeClass("d-none");
  } else {
    $('.email_setting_address').removeClass("d-none");
    $('.email_setting_port').removeClass("d-none");
    $('.email_setting_domain').removeClass("d-none");
    $('.email_setting_user_name').removeClass("d-none");
    $('.email_setting_password').removeClass("d-none");
    $('.email_setting_authentication').removeClass("d-none");
    $('.email_setting_enable_starttls_auto').removeClass("d-none");
    $('.email_setting_from').removeClass("d-none");

    $('.email_setting_sender').addClass("d-none");
  }
})
