require('bootstrap-select')

import tinymce from 'tinymce/tinymce.min'
import 'tinymce/models/dom/model.min'
import 'tinymce/icons/default/icons.min'
import 'tinymce/plugins/link/plugin.min'
import 'tinymce/plugins/autoresize/plugin.min'
import 'tinymce/plugins/pagebreak/plugin.min'
import 'tinymce/themes/silver/theme.min'
import 'tinymce-i18n/langs5/de'

$(document).on('turbolinks:load', function() {
  window.initialize()
});

 $(document).on('cocoon:after-insert', function() {
  window.initialize()
});

$(document).on("click", '[data-toggle="datepicker"]', function() {
  $(this).datetimepicker("toggle")
});

document.addEventListener("turbolinks:before-cache", function() {
  tinymce.remove()

  $('input.datepicker').datetimepicker("destroy");

  $('select').selectpicker('destroy')

  $('.with-tooltip').tooltip('hide')
})

window.initialize = function() {
  resizeLargeTables()
  $('.with-tooltip').tooltip({ container : 'body', html : true })

  $('select').selectpicker({
    noneSelectedText: "Bitte auswählen",
    selectAllText: "Alle auswählen",
    deselectAllText: "Alle abwählen",
    dropdownAlignRight: "auto"

  });

  $(".sortable").each(function() {
    let sortableElement = $(this)[0]
    Sortable.create(sortableElement, {
      handle: '.handle',
      //draggable: ".draggable",
      group: sortableElement,
      supportPointer: true,
      fallbackTolerance: 5,
      onUpdate: function(event) {
        let itemsIds = this.toArray()
        let itemsName = event.item.id.replace(/_[0-9]+$/, '');
        let oldIndex = event.oldIndex
        let newIndex = event.newIndex

        if (oldIndex > newIndex) {
          var startIndex = newIndex;
          var endIndex = oldIndex;
        } else {
          var startIndex = oldIndex;
          var endIndex = newIndex;
        }

        let changedItems = {}
        changedItems[itemsName] = {}
        for (let index = startIndex; index <= endIndex; index++) {
          changedItems[itemsName][index] = itemsIds[index];
        }

        $.ajax({
          url: this.el.dataset.url,
          type: "PATCH",
          data: changedItems,
          dataType: "script"
        });
      }
    });
  });

  $(".multisortable").each(function() {
    const sortableElement = $(this)[0]
    Sortable.create(sortableElement, {
      handle: '.handle',
      // draggable: ".draggable",
      group: sortableElement,
      multiDrag: true,
      fallbackTolerance: 5,
      supportPointer: true,
      multiDragKey: 'CTRL',
      onUpdate: function(event) {
        let itemsIds = this.toArray()
        let itemsName = event.item.id.replace(/_[0-9]+$/, '');

        if (event.items?.length) {
          if (event.oldIndicies[0].index > event.newIndicies[0].index) {
            var startIndex = event.newIndicies[0].index;
          } else {
            var startIndex = event.oldIndicies[0].index;
          }

          if (event.oldIndicies[event.items.length-1].index > event.newIndicies[event.items.length-1].index) {
            var endIndex = event.oldIndicies[event.items.length-1].index;
          } else {
            var endIndex = event.newIndicies[event.items.length-1].index;
          }

        } else {
          let oldIndex = event.oldIndex
          let newIndex = event.newIndex

          if (oldIndex > newIndex) {
            startIndex = newIndex;
            endIndex = oldIndex;
          } else {
            startIndex = oldIndex;
            endIndex = newIndex;
          }
        }

        let changedItems = {}
        changedItems[itemsName] = {}
        for (let index = startIndex; index <= endIndex; index++) {
          changedItems[itemsName][index] = itemsIds[index];
        }

        $.ajax({
          url: this.el.dataset.url,
          type: "PATCH",
          data: changedItems,
          dataType: "script"
        });
      }
    });
  });

  // Fix for multi sortable and row select
  $(".multisortable").children().each(function() {
    $(this).onClassChange((element, newClass) => {
      if (newClass.includes('active')) {
        Sortable.utils.select(element);
      } else {
        Sortable.utils.deselect(element);
      }
    });
  });

  const elements = document.querySelectorAll('.tinymce')
  elements.forEach(function (element) {
    tinymce.init({
      target: element,
      skin: false,
      content_css: false,
      branding: false,
      language: 'de',
      height: 500,
      plugins: 'link, autoresize, pagebreak',
      pagebreak_separator: '<div class="alwaysbreak"></div>',
      license_key: 'gpl',
      promotion: false,
      setup: function(editor) {
        editor.on('init', function() {
          element.parentElement.querySelector(".tinymce-loader").remove();
        });
      }
    })
  })

  $("input.datepicker").datetimepicker(window.options);
}

$(window).resize(function() {
  resizeLargeTables();
})

function resizeLargeTables() {
  $('.table-scrollable').each(function() {
    $(this).css('max-height', ($(window).height() - parseInt($(this).data("height"))) + "px");
  })
}

$(document).on("click", '.btn-clear-datepicker', function() {
  var input = $(this).closest(".form-group").find("input.datepicker")
  if (!input) { return }

  input.val("")
});

// jQuery extension method:
$.fn.onClassChange = function(cb) {
  return $(this).each((_, el) => {
    new MutationObserver(mutations => {
      mutations.forEach(mutation => cb && cb(mutation.target, mutation.target.className));
    }).observe(el, {
      attributes: true,
      attributeFilter: ['class'] // only listen for class attribute changes
    });
  });
}

window.localStorageAvailable = function() {
  try {
    const storage = window.localStorage;
    const testKey = "__storage_test__";
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      (e.name === "QuotaExceededError" || e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      window.localStorage &&
      window.localStorage.length !== 0
    );
  }
}

window.getSHA256Hash = async (input) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await crypto.subtle.digest("SHA-256", textAsBuffer);
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
};
