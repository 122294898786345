let
  photoCapture,
  photoCaptureViewport,
  photoCaptureContainer,
  photoCaptureCameraFeed,
  photoCaptureCanvas,
  photoCaptureCanvasContext,
  photoCaptureCanvasPreview,
  photoCaptureCanvasPreviewContext,
  targetFormId;

function initPhotoCapture() {
  photoCapture = document.getElementById('photo-capture');
  photoCaptureViewport = photoCapture.firstChild;
  photoCaptureContainer = photoCaptureViewport.firstChild;
  photoCaptureCameraFeed = document.getElementById('photo-capture-camera-feed');

  photoCaptureCanvas = document.getElementById('photo-capture-canvas');
  photoCaptureCanvasContext = photoCaptureCanvas.getContext('2d');

  photoCaptureCanvasPreview = document.getElementById('photo-capture-canvas-preview');
  photoCaptureCanvasPreviewContext = photoCaptureCanvasPreview.getContext('2d');
}

function clearPhotoCapture() {
  $('#photo-capture-canvas-preview').addClass('d-none');
  photoCaptureCanvasPreviewContext.clearRect(0, 0, photoCaptureCanvasPreview.width, photoCaptureCanvasPreview.height);
  photoCaptureCanvasContext.clearRect(0, 0, photoCaptureCanvas.width, photoCaptureCanvas.height);
}

function startPhotoCapture() {
  if (photoCaptureCameraFeed && photoCaptureCameraFeed.srcObject) return;

  $('#photo-capture').find('.video-container').append('<div class="loader"></div>');

  navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: 'environment',
      width: { ideal: 3264 },
      height: { ideal: 2160 }
    }
  })
    .then(stream => {
      photoCaptureCameraFeed.srcObject = stream;
      photoCaptureCameraFeed.onloadedmetadata = () => {
        photoCaptureCameraFeed.play();
        $('#photo-capture').find('.loader').remove();
        $(photoCaptureCameraFeed).removeClass('d-none');
        $('#take-photo').removeClass('d-none');

        let videoContainerDimensions = convertToPortraitSize({
          width: $(photoCaptureViewport).width(),
          height: $(photoCaptureViewport).height()
        });

        $(photoCaptureViewport).width(videoContainerDimensions.width);
        $(photoCaptureViewport).height(videoContainerDimensions.height);

        $(photoCaptureCameraFeed).height(videoContainerDimensions.height);

        if ($(photoCaptureCameraFeed).width() > $(photoCaptureCameraFeed).height()) {
          $(photoCaptureCameraFeed).height(videoContainerDimensions.height);
        } else {
          $(photoCaptureCameraFeed).width(videoContainerDimensions.width);
        }
      };
    })
    .catch(error => {
      $('#photo-capture').find('.loader').remove();

      $('#photo-capture-modal').modal('hide');
      dataConfirmModal.confirm({
        title: 'Achtung!',
        text: "Kamera konnte nicht gestartet werden. Bitte erlauben Sie den Zugriff auf die Kamera."
      });
      $('button').remove('.cancel');

      console.error('Error accessing camera', error);
    });
}

function stopPhotoCapture() {
  if (!photoCaptureCameraFeed) return;
  if (!photoCaptureCameraFeed.srcObject) return;

  photoCaptureCameraFeed.srcObject.getTracks().forEach(track => track.stop());
  photoCaptureCameraFeed.srcObject = null;
}

function drawPhotoCaptureImage() {
  if (!photoCaptureCameraFeed) return;

  let sourceFeedDimensions = { xOffset: 0, yOffset: 0, width: photoCaptureCameraFeed.videoWidth, height: photoCaptureCameraFeed.videoHeight };

  let viewDimensions = { width: $(photoCaptureViewport).width(), height: $(photoCaptureViewport).height() };
  let canvasDimensions = { width:  sourceFeedDimensions.width, height: sourceFeedDimensions.height };
  let canvasPreviewDimensions = { width: viewDimensions.width, height: viewDimensions.height };

  canvasDimensions = convertToPortraitSize(canvasDimensions);
  if (sourceFeedDimensions.width > canvasDimensions.width) {
    cropFactor = sourceFeedDimensions.height / viewDimensions.height;
    sourceFeedDimensions.width = viewDimensions.width * cropFactor;
    sourceFeedDimensions.xOffset = (photoCaptureCameraFeed.videoWidth - sourceFeedDimensions.width) / 2;
    canvasDimensions.width = sourceFeedDimensions.width;
  } else if (sourceFeedDimensions.height > canvasDimensions.height) {
    cropFactor = sourceFeedDimensions.width / viewDimensions.width;
    sourceFeedDimensions.height = viewDimensions.height * cropFactor;
    sourceFeedDimensions.yOffset = (photoCaptureCameraFeed.videoHeight - sourceFeedDimensions.height) / 2;
    canvasDimensions.height = sourceFeedDimensions.height;
  }

  // Make sure the canvas is the same size as the camera feed
  photoCaptureCanvas.width = canvasDimensions.width;
  photoCaptureCanvas.height = canvasDimensions.height;

  photoCaptureCanvasPreview.width = canvasPreviewDimensions.width;
  photoCaptureCanvasPreview.height = canvasPreviewDimensions.height;

  photoCaptureCanvasContext.drawImage(
    photoCaptureCameraFeed,
    sourceFeedDimensions.xOffset,
    sourceFeedDimensions.yOffset,
    sourceFeedDimensions.width,
    sourceFeedDimensions.height,
    0,
    0,
    canvasDimensions.width,
    canvasDimensions.height
  );

  photoCaptureCanvasPreviewContext.drawImage(
    photoCaptureCameraFeed,
    sourceFeedDimensions.xOffset,
    sourceFeedDimensions.yOffset,
    sourceFeedDimensions.width,
    sourceFeedDimensions.height,
    0,
    0,
    canvasPreviewDimensions.width,
    canvasPreviewDimensions.height
  );

  $(photoCaptureCanvasPreview).removeClass('d-none');
}

// Convert to 4x3 aspect ratio
function convertToPortraitSize(dimensions) {
  if (dimensions.height < dimensions.width) {
    width = (dimensions.height * 4) / 3;
    if (width < dimensions.width) {
      dimensions.width = width;
    } else {
      dimensions.height = (dimensions.width * 3) / 4;
    }
  } else {
    height = (dimensions.width * 4) / 3;
    if (height < dimensions.height) {
      dimensions.height = height;
    } else {
      dimensions.width = (dimensions.height * 3) / 4;
    }
  }

  return dimensions;
}

function submitTodoFormWithPhoto(photo) {
  if (photo == null || photo.size == 0) {
    console.error('No photo to submit');
    return;
  }

  let form = $("#" + targetFormId);
  let formData = new FormData(form[0]);
  formData.set('todo[photo]', photo);

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    data: formData,
    dataType: "script",
    processData: false,
    contentType: false,
    success: function() {
      window.initialize()
    }
  });
}

$(document).on('click', '#take-photo', function () {
  drawPhotoCaptureImage();
  stopPhotoCapture();
  $('#take-photo').addClass('d-none');
  $('#retake-photo').removeClass('d-none');
  $('#submit-photo').removeClass('d-none');
});

$(document).on("click", "#submit-photo", function(event) {
  event.preventDefault()
  console.log('submitting photo');
  photoCaptureCanvas.toBlob(submitTodoFormWithPhoto, "image/jpeg", 0.95);
  $('#photo-capture-modal').modal('hide');
});

$(document).on('click', '#retake-photo', function () {
  $('#photo-capture-canvas-preview').addClass('d-none');
  $('#retake-photo').addClass('d-none');
  $('#submit-photo').addClass('d-none');
  startPhotoCapture();
});

$(document).on('shown.bs.modal', '#photo-capture-modal', function () {
  initPhotoCapture();
  startPhotoCapture();
});

$(document).on('hide.bs.modal', '#photo-capture-modal', function () {
  stopPhotoCapture();
  clearPhotoCapture();
  $('#retake-photo').addClass('d-none');
  $('#submit-photo').addClass('d-none');
});

$(document).on('click', '.open-photo-capture', function(event) {
  event.preventDefault()
  targetFormId = $(this).data('target-form');
  $('#photo-capture-modal').modal('show');
});
