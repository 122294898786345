$(document).on("click", "[data-action='sidebar-open']", function() {
  $(this).addClass('toggled');
  $('.sidebar').addClass('toggled');
  $('header.header').append('<div class="backdrop" data-action="sidebar-close" data-target=".sidebar" />');
});

$(document).on("click", "[data-action='sidebar-close']", function() {
  $('.navigation-trigger').removeClass('toggled');
  $('.sidebar').removeClass('toggled');
  $('header.header .backdrop').remove();
});
