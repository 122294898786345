$(document).on("turbolinks:load", function () {
  initializeDatepicker();
});

$(document).on("change", ".new_todos_history_chart", function () {
  sendRequest();
});

$(document).on("click", '#todos_history_chart .btn-clear-datepicker', function () {
  $(this).closest(".form-group").find("input").val("");
  sendRequest();
});

function initializeDatepicker() {
  let start_date = $("input.todos_history_chart_start_date").val();
  let end_date = $("input.todos_history_chart_end_date").val();

  $('input.todos_history_chart_start_date, input.todos_history_chart_end_date').datetimepicker("destroy");

  var start_date_options = {}
  var end_date_options = {}
  if (start_date !== "" && end_date !== "") {
    start_date_options = $.extend({}, window.options, { date: new Date(start_date) });
    end_date_options = $.extend({}, window.options, { date: new Date(end_date) });
  } else if (start_date !== "") {
    start_date_options = $.extend({}, window.options, { date: new Date(start_date) });
    end_date_options = window.options;
  } else if (end_date !== "") {
    start_date_options = window.options;
    end_date_options = $.extend({}, window.options, { date: new Date(end_date) });
  } else {
    start_date_options = window.options;
    end_date_options = window.options;
  }

  $("input.todos_history_chart_start_date").datetimepicker(start_date_options);
  $("input.todos_history_chart_end_date").datetimepicker(end_date_options);

  $("input.todos_history_chart_start_date, input.todos_history_chart_end_date").on("change.datetimepicker", function (event) {
    sendRequest();
  });
}

function sendRequest() {
  $.ajax({
    type: $(".new_todos_history_chart").attr("method"),
    url: $(".new_todos_history_chart").attr("action"),
    data: $(".new_todos_history_chart").serialize(),
    dataType: "script",
    success: function () {
      window.initialize()
    }
  });
}
