export default function displayFlashMessage(message, type) {
  const messagesContainer = document.querySelector(".flash-messages-container");

  if (!messagesContainer) {
    return;
  }

  const messageElement = document.createElement("div");
  messageElement.classList.add("alert-dismissible", "fade", "show", "alert", "alert-" + type);
  messageElement.innerHTML  = "<button class=\"close\" data-dismiss=\"alert\" type=\"button\"><span>x</span></button>";
  messageElement.innerHTML += message;

  messagesContainer.appendChild(messageElement);

  setTimeout(() => messageElement.remove(), 5000);
}