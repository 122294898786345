$(document).on('click', '.todo-history-file', function (event) {
  event.preventDefault();
  var url = $(this).data('url');
  var form = $(this).closest("form");

  window.open(url + "?" + form.serialize(), '_blank').focus();
})

$(document).on('click', '.todo-history-print', function (event) {
  event.preventDefault();
  var url = $(this).data('url');
  var form = $(this).closest("form");

  printJS({
    printable: url + "?" + form.serialize(),
    type: 'pdf',
    showModal: true
  })
})