$(document).on("change", ".new_statistic_chart", function () {
  sendRequest();
});

function sendRequest() {
  $.ajax({
    type: $(".new_statistic_chart").attr("method"),
    url: $(".new_statistic_chart").attr("action"),
    data: $(".new_statistic_chart").serialize(),
    dataType: "script",
    success: function () {
      window.initialize()
    }
  });
}
