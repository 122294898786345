function sendRequest(source_filter) {
  let data = $(".storages_contents_form").serialize();
  if (source_filter) {
    data += "&source_filter=" + source_filter;
  }

  $.ajax({
    type: 'GET',
    url: '',
    data: data,
    dataType: "script"
  });
}

$(document).on("change", ".storages_contents_form select:not(#articles_branches_storage_branch_ids):not(#articles_branches_storage_storage_ids)", function () {
  sendRequest();
});

$(document).on("change", "#articles_branches_storage_branch_ids", function () {
  sendRequest("branches");
});

$(document).on("change", "#articles_branches_storage_storage_ids", function () {
  sendRequest("storages");
});

$(document).on('click', '.storage-contents-file', function (event) {
  event.preventDefault();
  var url = $(this).data('url');
  var form = $(this).closest("form");

  window.open(url + "?" + form.serialize(), '_blank').focus();
})

$(document).on('click', '.storage-contents-print', function (event) {
  event.preventDefault();
  var url = $(this).data('url');
  var form = $(this).closest("form");

  printJS({
    printable: url + "?" + form.serialize(),
    type: 'pdf',
    showModal: true
  })
})