$(document).on('click', ".select-default", function(event) {
  event.preventDefault()

  $(".default").val("0")
  $(this).parents(".article-card").find(".default").val("1")

  $(".select-default").removeClass("btn-success")
  $(".select-default").addClass("btn-primary")
  $(this).addClass("btn-success")
})
