$(document).on("click", ".supplyable-btn", function() {
  var element = $("select.supplyable")
  var elementId = $(this).data('id').toString()
  var elementIds = []

  if ($(this).hasClass('btn-primary')) {
    $(this).removeClass('btn-primary')
    $(this).addClass('btn-light')

    elementIds = element.val()
    var index = elementIds.indexOf(elementId)
    elementIds.splice(index, 1)
  } else {
    $(this).removeClass('btn-light')
    $(this).addClass('btn-primary')

    elementIds = element.val().concat(elementId)
  }

  element.val(elementIds).change()
})
