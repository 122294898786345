let timeout = null

function startSearch() {
  var query = $('.start-product-search').val().toLowerCase()
  var container = document.querySelectorAll("tr.transaction-overview")
  var items = document.querySelectorAll("tr.transaction-overview .searchable")
  var item;
  var product_name;

  for ( let i = 0; i < items.length; i++){
    item = items[i]
    product_name = item.textContent.toLowerCase()

    if (product_name.includes(query)) {
      container[i].style.display = "";
    } else{
      container[i].style.display = "none";
    }
  }
}

$(document).on("click", ".delete-product-search", function() {
  $(this).siblings().find('input')[0].value = '';
  clearTimeout(timeout);
  startSearch()
});

$(document).on("keyup", ".start-product-search", function(event) {
  clearTimeout(timeout);

  if (event.keyCode == 13) {
    startSearch()
  } else {
    var timeout = setTimeout(startSearch, 500);
  }
});

$(document).on("keydown", ".start-product-search", function(event) {
  if (event.keyCode == 13) {
    event.preventDefault();
  }
});