$(document).on('click', '.login-numpad', async function () {
  const tokenInput = $('.login-code');
  tokenInput.val(tokenInput.val() + $(this).data("number"));

  if (tokenInput.val().length < 4) return;

  const token = tokenInput.val().toString();

  if (!$("#connection-status").hasClass("disconnected")) {
    $(this).closest("form").trigger("submit");
    $(".overlay").css("display", "flex");
    updateEmployeeLoginData(token);
    return;
  }

  tokenInput.val("");

  if (!window.localStorageAvailable) return;

  const tokenHash = await getSHA256Hash(token);
  const employeeToken = localStorage.getItem("employeeToken");
  if (employeeToken !== tokenHash) {
    displayFlashMessage('Falscher Code. Offline kann sich nur der letzte Mitarbeiter anmelden.', "danger");
    return;
  }

  const lastVisitedPage = JSON.parse(localStorage.getItem("lastVisitedPage"));
  if (lastVisitedPage && lastVisitedPage.url === window.location.href) {
    $("nav").html(lastVisitedPage.nav);
    $("main").html(lastVisitedPage.main);

    lastVisitedPage.formsData.forEach(form => {
      const formElement = $(`#${form.id}`);
      form.items.forEach(item => {
        formElement.find(`[name="${item.name}"]`).val(item.value);
      });
    });
  }

  localStorage.removeItem("lastVisitedPage");
});

$(document).on('click', '.clear-login-form', function () {
  const tokenInput = $('.login-code');
  tokenInput.val(tokenInput.val().slice(0, -1));
});

async function updateEmployeeLoginData(token) {
  if (!window.localStorageAvailable) return;

  const employeeLoginPage = $("main").clone();
  const employeeToken = await getSHA256Hash(token);

  employeeLoginPage.find(".flash-messages-container").html("");

  localStorage.setItem("employeeLoginPage", employeeLoginPage.html());
  localStorage.setItem("employeeToken", employeeToken);
}

$(document).on('click', '[href="/users/sign_out"]', function () {
  if (!window.localStorageAvailable) return;

  localStorage.removeItem("employeeLoginPage");
  localStorage.removeItem("employeeToken");
  localStorage.removeItem("lastVisitedPage");
});
