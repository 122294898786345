$(document).on('mousedown touchstart', 'td.not-selectable', function (event) {
  event.stopPropagation()
  event.preventDefault()
})

$(document).on('mouseup touched', '.table-wrapper:not(.not-selectable) tbody tr', function (event) {
  let _this = this;
  let tableWrapper = $(this).closest('.table-wrapper');
  if (!$(this.parentElement).hasClass('multisortable') && tableWrapper.find('.multi-action').length === 0 || !event.ctrlKey) {
    tableWrapper.find('tr').each(function(_, row) {
      if (row == this) { return; }
      $(row).removeClass('active');
    }.bind(this));
  }

  if ($(this).hasClass('active')) {
    $(this).removeClass('active');
  } else {
    $(this).addClass('active');
  }

  switch ($("tr.active").length) {
  case 0:
    $('.single-action').addClass('disabled');
    $('.multi-action:not(.deactivate_disabled)').addClass('disabled');
    break;
  case 1:
    $('.single-action').addClass('disabled');
    $('.single-action:not([data-required])').removeClass('disabled');
    $('.single-action[data-required]').each(function() {
      if (Object.keys($(_this).data()).includes($(this).data('required'))) {
        $(this).removeClass('disabled');
      }
    })
    $('.multi-action:not(.deactivate_disabled)').removeClass('disabled');
    break;
  default:
    $('.single-action').addClass('disabled');
    $('.multi-action:not(.deactivate_disabled)').removeClass('disabled');
    break;
  }

  $('.single-action, .multi-action').each(function() {
    replaceAllPlaceholders(this);
  });
});

$(document).on('click', '.table-wrapper .btn-select-all', function() {
  if ($("tr[data-id]:not([class='active'])").length > 0) {
    $("tr[data-id]:not([class='active']").addClass('active')
    $('.single-action').addClass('disabled');
    $('.multi-action:not(.deactivate_disabled)').removeClass('disabled');
    $('.single-action, .multi-action').each(function() {
      replaceAllPlaceholders(this)
    });
  } else {
    $("tr[data-id][class='active']").removeClass('active')
    $('.single-action').addClass('disabled');
    $('.multi-action:not(.deactivate_disabled)').addClass('disabled');
  }
})

function replaceAllPlaceholders(element) {
  let url = decodeURIComponent($(element).data('url'))
  const placeholders = url.match(/{{(.*?)}}+/g);

  if (placeholders) {
    placeholders.forEach(function(placeholder) {
      let value = '';
      $('tr.active').each(function(index) {
        if (index > 0) {
          value += ',';
        }
        value += $(this).attr('data-' + placeholder.slice(2, -2))
      });
      url = url.replace(placeholder, value);
    })
    $(element).attr('href', url);
  }
};

$(document).on('click', '.print-pdf', function(event) {
  event.preventDefault();

  printJS({
    printable: $(this).attr('href'),
    type: 'pdf',
    showModal: true
  })
})
