$(document).on("change", "select#email_template_kind", function() {
  $.ajax({
    url: $(this).data("url"),
    type: "GET",
    dataType: "script",
    data: {
      kind: $(this).val()
    }
  })
})

$(document).on("click", ".copy-to-clipboard", function() {
  const element = document.createElement('textarea')
  element.value = $(this).data("copy")
  document.body.appendChild(element)
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)
})
