$(document).on('turbolinks:load', function () {
  $("[data-hidable='true']").each(function () {
    let input = document.getElementById($(this).data("input"));

    input.addEventListener("change", function () {
      let selected_value = input.value;

      if ($(this).data("values").includes(selected_value)) {
        $(this).addClass("d-none");
      } else {
        $(this).removeClass("d-none");
      }
    }.bind(this));

    input.dispatchEvent(new Event("change"));
  });
});
