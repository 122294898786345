$(document).on("click", "[data-behavior=check]", (event) => {
  const element = event.target;
  const checkboxElement = $(element).siblings("input[type=checkbox]");

  event.preventDefault();
  checkboxElement.trigger("click");
});

$(document).on("click", "[data-toggle=default]", (event) => {
  const element = event.target;
  const { branchId } = element.dataset;

  if (element.checked) {
    $("[data-toggle=default]")
      .filter(`[data-branch-id=${branchId}]`)
      .not(element)
      .prop("checked", false);
  }
});

$(document).on("submit", "[data-behavior=productsform]", () => {
  $(".nested-fields:not(:visible)")
    .find(".default-checkbox")
    .remove();
});
