let isConnected = true;
let lastActivityTime = null;

function updateTimer() {
  $.ajax({
    url: "/employee/logins",
    type: "patch",
    dataType: "json"
  });

  lastActivityTime = Date.now();
}

function checkConnection() {
  $.ajax({
    url: "/employee/logins/check",
    dataType: "json",
    success: function(data) {
      if (data.reload) {
        window.location = $(".check-session").data("url");
        clearInterval(window.checkConnectionInterval);
      } else {
        updateConnectionStatus(true);
      }
    },
    error: function() {
      updateConnectionStatus(false);
    }
  });
}

function checkLastActivity() {
  if (Date.now() - lastActivityTime > 5 * 60 * 1000) {
    logoutEmployeeUser();
  }
}

function logoutEmployeeUser() {
  if (window.location.href.includes('/employee/logins/new')) return;

  if (window.localStorageAvailable && !localStorage.getItem("lastVisitedPage")) {
    saveLastVisitedPage();
  }

  let form = $('<form>', { method: 'POST', action: '/employee/logins' });
  let csrfToken = $('meta[name="csrf-token"]').attr('content');
  let csrfParam = $('meta[name="csrf-param"]').attr('content');

  form.append($('<input>', { type: 'hidden', name: '_method', value: 'delete' }));
  form.append($('<input>', { type: 'hidden', name: csrfParam, value: csrfToken }));

  $('body').append(form);

  $.ajax({
    url: form.attr('action'),
    type: 'POST',
    data: form.serialize(),
    error: function() {
      if (!window.localStorageAvailable) return;
      const loginPage = localStorage.getItem("employeeLoginPage");
      $('nav').html('');
      $('main').html(loginPage);
    }
  });
}

function updateConnectionStatus(status) {
  isConnected = status;
  let connectionStatusHtml = "<div id='connection-status' class='fade show'><span class='label'></span></div>";

  if (isConnected) {
    $("#connection-status").removeClass("disconnected").addClass("connected").find(".label").text("Online");
    setTimeout(() => $("#connection-status").remove(), 5000);
    $(".btn-offline").removeClass("btn-offline disabled");
  } else {
    if ($("#connection-status").length < 1) {
      $(".header").append(connectionStatusHtml);
      $('.btn[data-url], .btn[href], input[type="submit"]').not(".btn-toggle-impossible, .disabled, a[data-method-custom]").addClass("btn-offline disabled");
    }
    $("#connection-status").removeClass("connected").addClass("disconnected").find(".label").text("Offline");
  }
}

$(document).on('turbolinks:load', function () {
  if (!window.location.href.includes('/employee')) return;

  if ($(".check-session").length > 0) {
    if (!window.checkLastActivityInterval) {
      window.checkLastActivityInterval = setInterval(checkLastActivity, 2000);
    }
    updateTimer();

    $(document).on('touchstart mousedown', updateTimer);
  }

  window.checkConnectionInterval = setInterval(checkConnection, 2000);

  $('a[data-method]').each(function() {
    $(this).attr('data-method-custom', $(this).data('method')).removeAttr('data-method');
  });

  if (window.location.href.includes('/employee/logins/new') || !window.localStorageAvailable) return;

  const lastVisitedPage = JSON.parse(localStorage.getItem("lastVisitedPage"));
  if (lastVisitedPage) {
    if (lastVisitedPage.url === window.location.href) {
      $("nav").html(lastVisitedPage.nav);
      $("main").html(lastVisitedPage.main);

      lastVisitedPage.formsData.forEach(function(form) {
        let formElement = $(`#${form.id}`);
        form.items.forEach(function(item) {
          formElement.find(`[name="${item.name}"]`).val(item.value);
        });
      });

      $("main").find("input, select, textarea").filter(function() {
        return $(this).parents('form').length === 0;
      }).each(function() {
        $(this).val(lastVisitedPage.inputs.find(input => input.name === $(this).attr("name")).value);
      });

      localStorage.removeItem("lastVisitedPage");
    } else {
      window.location.href = lastVisitedPage.url;
    }
  }
});

$(document).on('click', 'a[data-method-custom]', function(event) {
  event.preventDefault();
  event.stopPropagation();

  let form = $('<form>', { method: 'POST', action: $(this).attr('href') });
  let csrfToken = $('meta[name="csrf-token"]').attr('content');
  let csrfParam = $('meta[name="csrf-param"]').attr('content');
  let method = $(this).data('method-custom');

  form.append($('<input>', { type: 'hidden', name: '_method', value: method }));
  form.append($('<input>', { type: 'hidden', name: csrfParam, value: csrfToken }));
  $('body').append(form);

  $.ajax({
    url: form.attr('action'),
    type: 'POST',
    data: form.serialize(),
    success: function(response) {
      if (this.url === '/users/sign_out') {
        window.location.href = '/users/sign_in';
      }
    },
    error: function(xhr, status, error) {
      if (this.url === '/employee/logins') {
        saveLastVisitedPage();
        const loginPage = localStorage.getItem("employeeLoginPage");
        $('nav').html('');
        $('main').html(loginPage);
      }
    }
  });
});

$(document).on('click', 'a, input[type="submit"]', function(event) {
  if (window.location.href.includes('/employee') && !isConnected) {
    event.preventDefault();
  }
});

function saveLastVisitedPage() {
  if (!window.localStorageAvailable || window.location.href.includes('/employee/logins/new')) return;

  let nav = $("nav").clone();
  let main = $("main").clone();
  let formsData = [];
  let inputs = [];
  main.find(".flash-messages-container").html("");

  main.find("form").each(function() {
    let form = { id: $(this).attr("id"), items: [] };
    $(this).find("input, select, textarea").each(function() {
      form.items.push({ name: $(this).attr("name"), value: $(this).val() });
    });
    formsData.push(form);
  });

  main.find("input, select, textarea").filter(function() {
    return $(this).parents('form').length === 0;
  }).each(function() {
    inputs.push({ name: $(this).attr("name"), value: $(this).val() });
  });

  const lastVisitedPage = {
    nav: nav.html(),
    main: main.html(),
    formsData: formsData,
    inputs: inputs,
    url: window.location.href
  };

  localStorage.setItem("lastVisitedPage", JSON.stringify(lastVisitedPage));
  localStorage.removeItem("pendingStorageTransaction");
  localStorage.removeItem("pendingOrder");
}
